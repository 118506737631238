import React from "react";
import { Link } from "gatsby";
// import { Helmet } from "react-helmet";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";

const GameTest = () => (
  // <Layout>
  <div>
    {/* <Seo></Seo> */}
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />
      <div className="container">
      <div className="mx-auto py-12">
        <div className="flex">
        <iframe src="https://advertorial.immediate.co.uk/qualifio-v3.html" width="810" height="680"></iframe>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>

  // {/* // </Layout> */}
);

export default GameTest;
